$(".header__burger").click(function () {
    $(".header").toggleClass("header--active")
    $(".page").toggleClass("fixed-body")
})


$(".tab").click(function () {
    let path = $(this).attr("data-tabs-path")
    let parents = $(this).parents(".tabs-block")
    $(".tab").removeClass("tab--active")
    $(this).addClass("tab--active")
    $(parents).find(".tab__content").removeClass("tab__content--active")
    $(parents).find(`.tab__content[data-tabs-path="${path}"]`).addClass("tab__content--active")
})
